import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";
import cn from 'classnames';

const generateAltText = (name: string) => `Логотип клиента «${name}»`;

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: "С нами более 10 000 заведений ежедневно выполняют миллионы заказов",
			customers: [
				{
					name: "Металлург Магнитогорск",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/metallurg.png"}
							alt={generateAltText(name)}
							height={70}
							width={95}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Бодрый день",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/bodry.png"}
							alt={generateAltText(name)}
							height={70}
							width={100}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "DOBRO coffee",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/db.png"}
							alt={generateAltText(name)}
							height={70}
							width={55}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Флип",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/flip.png"}
							alt={generateAltText(name)}
							height={70}
							width={120}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "МГТУ им. Носова",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/mgtu.png"}
							alt={generateAltText(name)}
							height={70}
							width={60}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Еда и точка",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/eda.png"}
							alt={generateAltText(name)}
							height={70}
							width={80}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Мэд эспрессо тим",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/mad.png"}
							alt={generateAltText(name)}
							height={70}
							width={80}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "825",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/825.png"}
							alt={generateAltText(name)}
							height={70}
							width={60}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Кофедэй",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/coffeday.png"}
							alt={generateAltText(name)}
							height={70}
							width={80}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Майямми",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/yummy.png"}
							alt={generateAltText(name)}
							height={70}
							width={100}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Кафетериус",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/kafeterius.png"}
							alt={generateAltText(name)}
							height={70}
							width={130}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Бруклин бургерс",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/brooklyn.png"}
							alt={generateAltText(name)}
							height={70}
							width={60}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Матча",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/matcha.png"}
							alt={generateAltText(name)}
							height={70}
							width={100}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Бізбен бірге 10 000 астам мекемелер күн сайны миллиондаған тапсырыстар орындайды",
			customers: [
				{
					name: "Металлург Магнитогорск",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/metallurg.png"}
							alt={generateAltText(name)}
							height={70}
							width={95}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Бодрый день",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/bodry.png"}
							alt={generateAltText(name)}
							height={70}
							width={100}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "DOBRO coffee",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/db.png"}
							alt={generateAltText(name)}
							height={70}
							width={55}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Флип",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/flip.png"}
							alt={generateAltText(name)}
							height={70}
							width={120}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "МГТУ им. Носова",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/mgtu.png"}
							alt={generateAltText(name)}
							height={70}
							width={60}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Еда и точка",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/eda.png"}
							alt={generateAltText(name)}
							height={70}
							width={80}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Мэд эспрессо тим",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/mad.png"}
							alt={generateAltText(name)}
							height={70}
							width={80}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "825",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/825.png"}
							alt={generateAltText(name)}
							height={70}
							width={60}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Кофедэй",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/coffeday.png"}
							alt={generateAltText(name)}
							height={70}
							width={80}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Майямми",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/yummy.png"}
							alt={generateAltText(name)}
							height={70}
							width={100}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Кафетериус",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/kafeterius.png"}
							alt={generateAltText(name)}
							height={70}
							width={130}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Бруклин бургерс",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/brooklyn.png"}
							alt={generateAltText(name)}
							height={70}
							width={60}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Матча",
					logo: (name: string) => (
						<StaticImage
							className={styles.logo}
							src={"./assets/matcha.png"}
							alt={generateAltText(name)}
							height={70}
							width={100}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: "С нами более 10 000 заведений ежедневно выполняют миллионы заказов",
			customers: [
				{
					name: "Чебурекми",
					logo: (name: string) => (
						<StaticImage
							className={cn(styles.logo_by, styles.logo_by__cheb)}
							src={"./assets/by/cheburek.png"}
							alt={generateAltText(name)}
							height={70}
							width={110}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Кофе саунд",
					logo: (name: string) => (
						<StaticImage
							className={cn(styles.logo_by, styles.logo_by__sound)}
							src={"./assets/by/sound.png"}
							alt={generateAltText(name)}
							height={140}
							width={142}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Территория кофе",
					logo: (name: string) => (
						<StaticImage
							className={cn(styles.logo_by, styles.logo_by__terr)}
							src={"./assets/by/territory.png"}
							alt={generateAltText(name)}
							height={140}
							width={165}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Varka",
					logo: (name: string) => (
						<StaticImage
							className={cn(styles.logo_by, styles.logo_by__varka)}
							src={"./assets/by/varka.png"}
							alt={generateAltText(name)}
							height={70}
							width={59}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Wanted",
					logo: (name: string) => (
						<StaticImage
							className={cn(styles.logo_by, styles.logo_by__wanted)}
							src={"./assets/by/wanted.png"}
							alt={generateAltText(name)}
							height={140}
							width={139}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					name: "Перспектива",
					logo: (name: string) => (
						<StaticImage
							className={cn(styles.logo_by, styles.logo_by__pers)}
							src={"./assets/by/perspectiva.png"}
							alt={generateAltText(name)}
							height={140}
							width={252}
							layout={"fixed"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			],

		}
	}

	return {
		title: "С нами более 10 000 заведений ежедневно выполняют миллионы заказов",
		customers: [
			{
				name: "Металлург Магнитогорск",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/metallurg.png"}
						alt={generateAltText(name)}
						height={70}
						width={95}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "Бодрый день",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/bodry.png"}
						alt={generateAltText(name)}
						height={70}
						width={100}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "Флип",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/flip.png"}
						alt={generateAltText(name)}
						height={70}
						width={120}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "МГТУ им. Носова",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/mgtu.png"}
						alt={generateAltText(name)}
						height={70}
						width={60}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "Еда и точка",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/eda.png"}
						alt={generateAltText(name)}
						height={70}
						width={80}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "Мэд эспрессо тим",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/mad.png"}
						alt={generateAltText(name)}
						height={70}
						width={80}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "825",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/825.png"}
						alt={generateAltText(name)}
						height={70}
						width={60}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "Кофедэй",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/coffeday.png"}
						alt={generateAltText(name)}
						height={70}
						width={80}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "Майямми",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/yummy.png"}
						alt={generateAltText(name)}
						height={70}
						width={100}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "Кафетериус",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/kafeterius.png"}
						alt={generateAltText(name)}
						height={70}
						width={130}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "Бруклин бургерс",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/brooklyn.png"}
						alt={generateAltText(name)}
						height={70}
						width={60}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				name: "Матча",
				logo: (name: string) => (
					<StaticImage
						className={styles.logo}
						src={"./assets/matcha.png"}
						alt={generateAltText(name)}
						height={70}
						width={100}
						layout={"fixed"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
		],
	}
}

