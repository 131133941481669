import React, { useEffect, useRef } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import {getData} from "./data"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
	isGray?: boolean
	isPricePage?: boolean
}

export default function OurCustomers(props: Props) {
	const logoContainer: { current: Array<HTMLElement | null> } = useRef([]);
	const localizationContext = useLocalizationContext();

	// const getRandomOrder = (): number => {
	// 	return Math.ceil(Math.random() * getData(localizationContext.locale).customers.length)
	// }
	//
	// /*
  //   Перемешивание логотипов через order, потому что если перемешивать массив с клиентами,
  //   Гетсби кидает список через спину и раскидывает каждый логотип в чужой контейнер с чужим размером.
  //   */
	// useEffect(() => {
	// 	logoContainer.current.forEach(logo => {
	// 		if (logo) {
	// 			logo.style.order = "" + getRandomOrder()
	// 		}
	// 	})
	// }, [])

	return (
		<section
			className={classNames(
				styles.section,
				props.className,
				{ [styles.sectionGray]: props.isGray },
				{ [styles.sectionPrice]: props.isPricePage }
			)}
		>
			<h2
				className={classNames(styles.title, {
					[styles.titleGray]: props.isGray,
				})}
			>
				{getData(localizationContext.locale).title}
			</h2>
			<div className={styles.logoListContainer}>
				<ul
					key={localizationContext.locale}
					className={classNames(
						styles.logoList,
						{ [styles.logoList__by]: localizationContext.locale === 'ru-BY' },
						{ [styles.logoListGray]: props.isGray },
						{ [styles.logoListPrice]: props.isPricePage }
					)}
				>
					{getData(localizationContext.locale).customers.map((client, i) => (
						<li key={client.name} data-name={client.name}>
							{client.logo(client.name)}
						</li>
					))}
				</ul>
			</div>
		</section>
	)
}
